/* Outer wrapper to center the login box on the screen */
.outer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Full viewport height */
    padding: 20px; /* Padding to add space around the edges */
    box-sizing: border-box; /* Ensures padding is considered in total width */
    background-color: #f0f2f5; /* Light background for contrast */
  }
  
  /* Login box styling */
  .login-container {
    width: 100%;
    max-width: 400px;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }
  
  .login-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Form styling */
  .login-form {
    width: 100%; /* Full width inside the container */
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Input field styling */
  .login-input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
  }
  
  /* Button styling */
  .login-button, .secondary-button {
    padding: 12px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%; /* Full width button for a clean look */
  }
  
  .login-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .secondary-button {
    background-color: #f7f7f7;
    color: #333;
    border: 1px solid #ccc;
  }
  
  .secondary-button:hover {
    background-color: #e6e6e6;
  }
  
  .login-button:hover {
    background-color: #45a049;
  }
  
  /* Additional text styling */
  .login-text {
    font-size: 0.9em;
    color: #666;
    margin: 15px 0;
  }
  
  .reset-message {
    font-size: 0.9em;
    color: #4CAF50;
    margin-top: 10px;
  }
  