/* Footer styling */
.footer {
    width: 100%;
    height: 60px;
    padding: 15px 0;
    background-color: #282c34;
    color: #61dafb;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .footer p {
    margin: 0;
    color: #61dafb;
    transition: color 0.3s ease;
  }
  
  .footer p:hover {
    color: #ffffff;
  }
  