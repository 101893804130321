/* Outer container for centering content */
.outer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f2f5;
  }
  
  /* Inner container styling */
  .upload-container {
    width: 100%;
    max-width: 500px;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Title styling */
  .upload-title {
    font-size: 1.8em;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Text input and file input styling */
  .upload-input,
  .file-input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Upload button styling */
  .upload-button {
    margin-top: 20px;
    padding: 12px 24px;
    font-size: 1em;
    color: white;
    background-color: #4CAF50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .upload-button:hover {
    background-color: #45a049;
  }
  