.header {
    padding: 15px 0;
    background-color: #282c34;
    text-align: center;
    position: relative;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: 1000;
  }
  
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .info-icon {
    font-size: 1.2rem;
    color: #61dafb;
    cursor: pointer;
    margin-right: 8px;
  }
  
  .logo {
    color: #61dafb;
    font-size: 1.8rem;
    font-weight: bold;
    text-decoration: none;
  }
  
  .right-container {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .user-info {
    color: #ffffff;
    font-size: 1rem;
  }
  
  .login-link {
    color: #61dafb;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .hamburger {
    font-size: 1.8rem;
    color: #61dafb;
    cursor: pointer;
  }
  
  .nav-list {
    list-style-type: none;
    margin: 0;
    padding: 10px 0;
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #333;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
  }
  
  .nav-link {
    color: #61dafb;
    text-decoration: none;
    font-size: 1rem;
    padding: 10px 20px;
  }
  
  .nav-link:hover {
    color: #fff;
  }
  
  .logout-button {
    color: #61dafb;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    padding: 10px 20px;
    text-align: left;
    display: block;
    width: 100%;
  }
  
  .logout-button:hover {
    color: #ffffff;
    text-decoration: underline;
  }
  
  .info-popup {
    position: absolute;
    top: 70px;
    left: 20px;
    background-color: #333;
    color: #f0f2f5;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-size: 0.9rem;
    z-index: 1000;
    max-width: 250px;
    text-align: left;
  }
  
  .device-info {
    margin-bottom: 5px;
  }
  
  .location-info {
    margin-top: 5px;
  }
  