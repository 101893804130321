/* Outer container to center content */
.outer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f2f5;
  }
  
  /* Inner container for the About content */
  .about-container {
    width: 100%;
    max-width: 600px;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* Title styling */
  .about-title {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Content paragraph styling */
  .about-content {
    font-size: 1.1em;
    color: #666;
    line-height: 1.6;
  }
  