/* Outer container for centering content */
.outer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f2f5;
}
  
/* Inner container styling */
.home-container {
    width: 100%;
    max-width: 600px;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
  
/* Title styling */
.home-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
}
  
/* Content paragraph styling */
.home-content {
    font-size: 1.1em;
    color: #666;
    line-height: 1.6;
}
  
/* Subtitle styling */
.home-subtitle {
    font-size: 1.5em;
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
}
  
/* Button styling */
.join-button {
    margin-top: 20px;
    padding: 12px 24px;
    font-size: 1em;
    color: white;
    background-color: #4CAF50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.join-button:hover {
    background-color: #45a049;
}

/* How It Works section - center-aligned in container but left-aligned within itself */
.how-it-works {
    text-align: left;
    margin: 0 auto; /* Center aligns the how-it-works container within home-container */
    max-width: 300px; /* Keeps the section visually balanced */
    line-height: 1.6;
    margin-top: 10px;
}
