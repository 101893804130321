/* Outer container to center content */
.outer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 10px 20px; /* Reduced padding for a tighter layout */
    box-sizing: border-box;
    background-color: #f0f2f5;
  }
  
  /* Gallery container styling */
  .gallery {
    width: 100%;
    max-width: 800px;
    text-align: center;
    padding: 20px; /* Added padding for consistent spacing within */
    border-radius: 8px;
  }
  
  .gallery-title {
    font-size: 1.8em; /* Slightly reduced font size for balance */
    color: #333;
    margin: 10px 0 20px 0; /* Reduced top margin for tighter space to the header */
  }
  
  /* Gallery grid layout */
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    margin-top: 10px; /* Adds consistent spacing from the title */
  }
  
  /* Individual gallery items */
  .gallery-item {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .gallery-item:hover {
    transform: scale(1.05);
  }
  
  /* Text overlay on gallery items */
  .gallery-item-text {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    text-align: center;
    font-size: 0.9em;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .gallery-item:hover .gallery-item-text {
    opacity: 1;
  }
  
  /* Full-screen overlay for zoomed image */
  .zoom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000;
  }
  
  /* Zoomed image styling */
  .zoomed-image {
    width: 80%;
    max-width: 800px;
    height: 80%;
    max-height: 600px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  }
  