/* Outer wrapper to center the register box on the screen */
.outer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f2f5;
  }
  
  /* Register box styling */
  .login-container {
    width: 100%;
    max-width: 400px;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }
  
  .login-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Form styling */
  .login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Input field styling */
  .login-input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
  }
  
  /* Button styling */
  .login-button, .secondary-button {
    padding: 12px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .login-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .secondary-button {
    background-color: #f7f7f7;
    color: #333;
    border: 1px solid #ccc;
  }
  
  .secondary-button:hover {
    background-color: #e6e6e6;
  }
  
  .login-button:hover {
    background-color: #45a049;
  }
  